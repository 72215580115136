<template>
  <div class="page-template-home-closed-new">
      
      <br /><br />
       
      <div class="collection-description" v-if="selectedFilter=='solar'">
       
        <div class="title-collection">Collection <span class="outline">Solaire</span></div>
        
        <div   class="collection-text">Toutes les montures de  la collection optique et solaire Senseye sont personnalisables. Notre marque  s’engage à chaque étape de conception de ses produits. Nous fabriquons français, des lunettes impression 3D spécialement conçues pour le bout de votre nez, dans le respect de vos envies de consommer plus responsables. 
        </div><br /><br />
        
      </div>
       <div class="collection-description" v-if="selectedFilter=='optic' && loaded">
        <div class="title-collection">Collection <span class="outline">Optique</span></div>
        <div class="collection-text">Toutes les montures de  la collection optique et solaire Senseye sont personnalisables. Notre marque  s’engage à chaque étape de conception de ses produits. Nous fabriquons français, des lunettes impression 3D spécialement conçues pour le bout de votre nez, dans le respect de vos envies de consommer plus responsables. 
        </div><br /><br />
        
      </div>
      <div class="collection-filter" v-if="loaded">
          <div class="item" @click="filterCollection('solar')" >SOLAIRE</div>
          <div class="item" @click="filterCollection('optic')" >OPTIQUE</div>
          
          <div class="bar" :style="'left:'+barPosition+'%;width:'+barWidth+'%;'"></div>
      </div>
      <br /><br />
       <div class="contenumob select-column">
            <img src="../../../public/assets/images/col1.svg" width="12" @click="columns=1" :class="{active:columns==1}" />    <img @click="columns=2" src="../../../public/assets/images/col2.svg" :class="{active:columns==2}" width="12" /> 
            <br /><br />
        </div>
    <div class="collection home-section-new">
        
        <div class="products" :class="{cols2:columns==2}">
              <div class="carousel-cell product" v-for="(glass, index) in glasses" :key="glass.id" v-show="(glass.solar==true && selectedFilter=='solar') || selectedFilter=='optic'">
                
                <a  @click="$router.push({name:'glassDetail', params: { id: glass.id }})"></a>
                    <img :src="photos[index]" class="product-image" />
                    <div class="personnalisation-actions">
                        <div class="colors-count">{{glass.colors.length}} <span class="pls">+</span> couleurs</div>
                        <div class="personnalisation-button">Personnaliser</div>
                    </div>
                    <div class="like" @click="likePhoto(glass.liked,glass.like_id,index)">
                        <img v-if="glass.liked==false" src="../../../public/assets/images/heart.svg" width="26" />   
                        <img v-else src="../../../public/assets/images/heart.png" width="26" />
                    </div>
                    
                    <div class="bottom">
                        <span class="product-title">{{glass.name}}</span>
                        <span class="product-price">À partir de {{glass.price}}€</span>
                    </div>
                
            </div>

            <div class="product carousel-cell">
            <div class="soon-message">Bientôt disponible</div>
            <div class="flou"></div>
             <div class="personnalisation-actions">
                        <div class="colors-count">1 <span class="pls">+</span> couleurs</div>
                        <div class="personnalisation-button">Personnaliser</div>
                    </div>
                    <div class="like">
                        <img src="../../../public/assets/images/heart.svg" width="26" />   
                    </div>
                <img src="../../../public/assets/images/2021/05/lunette.png" class="product-image" />
                
                
                
            </div>

              <div class="product carousel-cell">
            <div class="soon-message">Bientôt disponible</div>
            <div class="flou"></div>
             <div class="personnalisation-actions">
                        <div class="colors-count">1 <span class="pls">+</span> couleurs</div>
                        <div class="personnalisation-button">Personnaliser</div>
                    </div>
                    <div class="like">
                        <img src="../../../public/assets/images/heart.svg" width="26" />   
                    </div>
                <img src="../../../public/assets/images/2021/05/lunette.png" class="product-image" />
                
                
                
            </div>

            <div class="product carousel-cell">
          
                
                
            </div>
        </div>
       
    
    </div>
    
    <br /><br /><br /><br />
   
  </div>
</template>

<script>

import jQuery  from 'jquery';
import emitter from 'tiny-emitter/instance'

import {APIRequest} from "@simb2s/senseye-sdk"


export default {
    name: 'Collection',
    components: {
        

    },
    data() {
        return {
        barWidth:50,
        loaded:false,
        barPosition:0,
        glasses:[],
        controller: null,
        tween: null,
        columns:1,
        scene: null,
        email:null,
        currentOption:'colors',
        showModal:false,
        currentImage:'',
        currentColor:'color0',
        showDevices:false,
        selectedFilter:'solar',
        flickityOptions: {
        cellAlign: 'center',
        prevNextButtons: false,
        contain: true,
        
        photos:[]
      }
        }
    },
    methods:{
        async likePhoto(liked,like_id,glass_index)
        {
            if(liked==false)
            {
                
                if(localStorage.id_user_senseye_auth)
                {
                    if(localStorage.id_user_senseye_auth!=null && localStorage.id_user_senseye_auth!='')
                    {
                        let data={}
                        data.user={}
                        data.glass={}
                        data.glass.id=this.glasses[glass_index].id
                        data.user.id=localStorage.id_user_senseye_auth
                        await APIRequest.create('likes',data)
                    }
                   
                }
                this.glasses[glass_index].liked=true;
            }
            else
            {
                if(like_id!=null)
                {
                    await APIRequest.delete('likes',like_id)
                }
                this.glasses[glass_index].liked=false;
            }
        },
        filterCollection(filter)
        {
            this.selectedFilter=filter
            if(filter=='solar')
            {
                this.barWidth=50
                this.barPosition=0
            }
            else
            {
                this.barWidth=50
                this.barPosition=50
            }
        },
        closeModal($event)
        {
             if (jQuery($event.target).parents('.content').length) {

        }
            else
            {
                this.showModal=false
            }
        },
        async getGlasses()  {
            let glasses=await APIRequest.list('glasses',null,'collection')
            this.glasses=glasses[0]
            this.photos=[]
            for(let i=0;i<this.glasses.length;i++)
            {
                let photo=this.glasses[i].photos
                let photos=this.glasses[i].photos;
                photo=photos[0];
                this.photos.push(photo)
            }
        },
        changeNewsletterEmail(email)
        {
            this.email=email
        },
        chooseOption(option)
        {
            this.currentOption=option
            this.currentColor='color1'
        },
        displayModal($event)
        {
            this.currentImage=jQuery($event.target).attr('src');
            this.showModal=true;
        },
        chooseColor(color)
        {
            this.currentColor=color
        },
        displayDevices()
        {
            if(this.showDevices==true)
            {
                this.showDevices=false
            }
            else
            {
                this.showDevices=true
            }
        }
    },
    async mounted()
    {
        await this.getGlasses();
      
        emitter.on('changeNewsletterEmail', function (email) {
        this.changeNewsletterEmail(email)
        }, this);

        this.loaded=true
    }
}
</script>
<style scoped>

.colors-count .pls
{
background: linear-gradient(80deg, #95B4F2 0%, #9F96F0 100%) 0% 0% no-repeat padding-box;
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.personnalisation-button::before
{
    content:' ';
    position:absolute;
    left:5px;
    top: 7px;
    width:13px;
    height:13px;
    background-size:100% 100%;
    display:block;
    background-image:url(../../../public/assets/images/bullet.svg);
}
.personnalisation-button
{
    position:relative;
    font-size:13px;
    background: transparent linear-gradient(90deg, #CBDCF8 0%, #DDD5F7 100%) 0% 0% no-repeat padding-box;
border: 1px solid #FFFFFF00;
border-radius: 8px;
padding:5px 10px 5px 25px;
}
.products.cols2 .personnalisation-actions
{
    left:5px;
    top:5px;
}
.products.cols2 .personnalisation-button
{
    padding: 3px 7px 3px 15px;
}
.products.cols2 .personnalisation-button::before
{
    top:4px;
    width: 9px;
    height: 9px;
    left:4px;
}
.products.cols2 .like
{
    right:5px;
    top:5px;
}
.products.cols2 .like img 
{
    width:15px;
}
.like
{
    cursor:pointer;
    position:absolute;
    top:10px;
    z-index:2;
    text-align: right;
    right:15px;
}
.personnalisation-actions
{
    position:absolute;
    top:10px;
    text-align: left;
    left:15px;
}
.colors-count
{
    
    font-size:13px;

}
.carousel-cell a
{
    text-decoration: none;
    color:#000;
    position:absolute;
    z-index:1;
    left:0;
    top:0;
    width:100%;
    height:100%;
}
.title-collection .outline
{
    background-image: -webkit-linear-gradient(#000 50%, #000 50%);
    background-position: 0 0;
    background-size: 0% 0%;
    text-stroke: 1px #000;
    -webkit-text-stroke: 1px #000;
    color: #fff;
    white-space: nowrap;
    color: transparent;
}
.title-collection
{
    font-size:70px;
}
.collection-description
{
    text-align:center;
    max-width:700px;
    width:70%;
    margin-left:auto;
    margin-right:auto;
}
.collection-filter .item:nth-child(2)
{
width:50%;
}
.collection-filter .item:first-child
{
width:50%;
}
.collection-filter .item:nth-child(3)
{
width:48%;
}
.collection-filter .item
{
    
    cursor:pointer;
    display:inline-block;
}
.collection-filter .bar
{
    transition:all 0.5s ease-in-out;
    width:50%;
    height:2px ;
    background:#000;
    position:absolute;
    bottom:0;
}
.collection-filter
{
    position:relative;
    font-size:18px;
    text-align: center;
    width:380px;
    margin-left:auto;
    margin-right:auto;
    border-bottom:0.5px solid #808080;
    padding-bottom:10px;
}
.collection-text
{
    font-size:13px;
}
@media screen and (max-width:760px) {
    .collection-filter
    {
        width:320px;
        font-size:15px;
    }
    .collection-description
    {
        width:90%;
    }
    .title-collection
    {
        font-size:32px;
    }

    body .products
    {
        padding-left:5%;
        padding-right: 5%;
    }
    body .products .product .bottom .product-title
    {
        font-size:13px;
    }

    body .products.cols2 .colors-count
    {
        font-size:8px;
    }
    body .products.cols2 .personnalisation-button
    {
        font-size:8px;
    }
    body .products.cols2 .product
    {
        width:48%;
    }
    
    body .products.cols2 .product .bottom .product-price
    {
        font-size:8px;
    }
    .select-column img.active
    {
        opacity:1;
    }
    
    .select-column img
    {
        opacity:0.5;
        display:inline-block;
        margin-left:5px;
        margin-right: 5px;
    }
    .select-column
    {
        width:100%;
        text-align: center;
    }
}
.loader
{	

	background:rgba(255,255,255,0.8);
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;

	z-index:6;
}
 .loader img
{
	width:200px;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}


.product.carousel-cell .soon-message
{
	z-index:3;
	position: absolute;
    top: 50%;
	font-size:20px;
	
	transform:translate(-50%,-50%);
    left: 50%;
}
.product.carousel-cell .flou
{
		width:100%;
	height:100%;
	position: absolute;
    top: 0;

    left: 0;
	background: #FFFFFF26 0% 0% no-repeat padding-box;
	opacity: 1;
z-index:2;
backdrop-filter: blur(24px);

	-webkit-backdrop-filter: blur(24px);
}
img.product-image {
    width:100%;
    height:auto;
}
.collab-section.product .right .product_choice_link img
{
	display:inline-block !important;
}

.collaboration  div.products
{
	text-align:left;
}
div.products
{
	text-align:center;
}
div.products.cols2 div.product .bottom
{
	bottom:-35px;
}
div.products div.product .bottom .product-price
	{
		font-size:13px;
		display:block;
	}
	div.products div.product .bottom
	{
		position:absolute;
		bottom:-50px;
		text-align:left;
		width:100%;
		left:0;
	}
	div.products div.product .top .custom span
	{
		position:absolute;
		width:95%;
		text-align:center;
		left:50%;
		min-width:200px;
		opacity:0;
		transition:all 0.4s;
		top:50%;
		transform:translate(-50%,-50%);
	}
	div.products div.product .top:hover .custom span
	{
		opacity:1;
	}
	div.products div.product .top .plus:before
	{
		transition:all 0.3s ease-in-out;
	}
	div.products div.product .top:hover .plus:before
	{
			transform:translate(-50%,-50%) rotate(90deg);
	}		
	div.products div.product .top:hover .like
	{
		opacity:1;
	}
	div.products div.product .top:hover .custom
	{
		width: 60%;
		
	}
	div.products div.product .top .custom
	{
		
		transition:all 0.4s;
		width: 0%;
height: 104px;
background: #2A2A29 0% 0% no-repeat padding-box;
border: 1px solid #70707000;
vertical-align:top;
text-align:center;
    float: left;
color:#fff;
padding-top:30px;
position:relative;
display:inline-block;
font-size:20px;
	}
	div.products div.product .top .like:before
	{
		background-image:url(../../../public/assets/images/2021/04/like.svg);
	background-size:100% 100%;
	width:23px;
	position:absolute;
	left:50%;
	top:50%;
	transform:translate(-50%,-50%);
	cursor:pointer;
	
	height:23px;
	content:" ";
	display:block;
	}
	div.products div.product .options .option-head .label.active
	{
		opacity:1;
		border-bottom:2px solid black;
	}
	div.products div.product .options .option-head
	{
		margin-left:auto;
		margin-right:auto;
		display:block;
		height:40px;
		width:110px;
	}
	div.products div.product .options .option-head .label
	
	{
	padding-left:10px;
	font-size:10px;
	cursor:pointer;
	float:left;
	padding-right:10px;
	border-bottom:1px solid black;
	opacity:0.5;
	}
	div.products div.product .options .color .bg.bg-lense
	{
		background:transparent !important;
	}
	div.products div.product .options .color .bg
{
	width:39px;
	height:39px;
	border-radius:50%;
	position:absolute;
	bottom:2px;
	left:50%;
	
	transform:translateX(-50%);
	background:#000;
}
	div.products div.product .options
	{
		position:absolute;
		bottom:0;
		left:0;
		width:100%;
		text-align:center;
	}
	div.products div.product .top .like
	{
		width: 20%;
height: 104px;
    float: right;
	position:relative;
	opacity:0;

display:inline-block;
vertical-align:top;
	}
	div.products div.product .top .plus:before
	{
		background-image:url(../../../public/assets/images/2021/04/plus-w.png);
	background-size:100% 100%;
	width:33px;
	position:absolute;
position:absolute;
	left:50%;
	top:50%;
	transform:translate(-50%,-50%);
	cursor:pointer;
	height:33px;
	content:" ";
	display:block;
	}
    div.products div.product
	{
		background:#F8F8F8;
		padding:0px;
		width:500px;
		display:inline-block;
		vertical-align:top;
		position:relative;
		min-height:500px;
		margin-left:1%;
		margin-bottom:130px;
		margin-right:1%;
	}
	div.products div.product .top .plus
	{
		width: 20%;
		    float: left;
			position:relative;
height: 104px;
background: #5186EC 0% 0% no-repeat padding-box;
border: 1px solid #70707000;
display:inline-block;
vertical-align:top;
	}
	div.products div.product .top
	{
		position:absolute;
		top:0;
		left:0;
		width:100%;
	}
	div.products .product .options .color.active .contour,
div.products .product .options .color.active .title
{
	opacity:1;
}
div.products .product .options .option
{
	display:block;
	width:100%;
	padding-top:10px;
}
div.products .product .options .color .contour
{
	opacity:0;
	width:43px;
	height:43px;
	border-radius:50%;
	position:absolute;
	bottom:0px;
	left:50%;
	
	transform:translateX(-50%);
	background:transparent;
	border:1px solid black;
}
div.products .product .options .color .title
{
	font-size:10px;
	text-align:center;
	line-height:10px;
	padding-bottom:0;
	font-family:'pragmatica';
	display:block;
	opacity:0;
	transition: all .7s cubic-bezier(.23,1,.32,1) .2s;
}


body .filters-collection .category .button
{
	margin-bottom:10px;
}
div.products .product:hover .top,
div.products .product:hover .bottom .add-to-cart
{
	opacity:1;
}
div.products .product .top
{
	opacity:0;
	transition:all 0.4s;
}
div.products .product .bottom .add-to-cart
{
	padding-left: 30px;
	
	opacity:0;
	transition:all 0.4s;
    padding-top: 15px;
	text-decoration:none !important;
	margin-top:15px;
    position: relative;
	margin-left:auto;
	margin-right:auto;
	width:240px;
	display:block;
    padding-bottom: 15px;
    padding-right: 50px;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #837a7a39;
    color: #fff;
    border-radius: 48px;
   
}
div.products .product .options .color
{
	display:inline-block;
	    width: 41px;
		cursor:pointer;
		vertical-align:top;
    height: 55px;
	position:relative;
}
</style>